html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}

.carousel {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
}

.full-screen {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}

.image-slide {
  max-width: 100%;
  max-height: 80vh;
}

div .smaller-slide {
  max-height: 70vh;
}

.slide-arrow {
  color: #14B6D4;
  cursor: pointer;
  font-size: 2rem;
  position: absolute;
  top: 300px;
}

.slide-arrow.right {
  right: 1rem;
  z-index: 3;
}

.slide-arrow.left {
  left: 1rem;
  z-index: 3;
}

.gallery-image {
  display: inline;
  width: 300px;
  margin-bottom: 20px;
}

.no-scroll {
  overflow: hidden;
}

.fixed-bottom-right {
  position: fixed;
  bottom: 0;
  height: 75px;
  width: 100%;
  background-color: black;
  border-top: 1px solid #e5e5e5;
}

@media only screen and (max-width: 500px) {
  .fixed-bottom-right {
    font-size: 8px;
  }
}

.float-right {
  float: right;
}

.margin-20 {
  margin: 20px;
}

.margin-top-75 {
  margin-top: 75px;
}

.float-left {
  float: left;
}

.color-white {
  color: white;
}

#gallery-grid {
  margin: 0 auto;
}

.grid-item {
  margin-bottom: 20px;
  width: 300px;
}

.grid-item:hover {
  cursor: pointer;
}

.logo {
  max-width: 60%;
}

.min-75px {
  min-height: 75px;
}

@media only screen and (max-width: 800px) {
  .logo {
    max-width: 50%;
  }
  .change-width {
    width: 50px;
  }
}

@media only screen and (max-width: 500px) {
  .change-width {
    width: 40px;
  }
}


@media only screen and (max-width: 800px) {
  .gallery {
    margin-top: 50px;
  }
}

.inline {
  display: inline;
}

.fixed-bottom-center {
  position: fixed;
  bottom: 0px;
  height: 100%;
  width: 100%;

  /* this is what centers your element in the fixed wrapper*/
  display: flex;
  flex-flow: column nowrap;
  justify-content: center; /* aligns on vertical for column */
  align-items: center; /* aligns on horizontal for column */
}

.black-text {
  color: grey;
}

.center {
  margin: 0 auto;
}

.max500 {
  max-height: 500px;
  max-width: 100%;
}

.out-link:hover img {
  height: 100px;
  color: palevioletred;
  -webkit-transition: height 1s; /* Safari */
  transition: height 1s;
}

.big-out-link:hover img {
  height: 200px;
  color: palevioletred;
  -webkit-transition: height 1s; /* Safari */
  transition: height 1s;
}

.out-link img {
  height: 80px;
  -webkit-transition: height 1s; /* Safari */
  transition: height 1s;
}

.out-link:hover h3 {
  color: palevioletred;
}

.out-link:hover {
  text-decoration: none;
}

.line {
  border: 1px solid palevioletred;
  margin-left: 100%;
  margin-right: 100%;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  -webkit-transition: margin 1s; /* Safari */
  transition: margin 1s;
  transition-timing-function: ease-out;
  display: none;
}

.out-link:hover .line {
  margin-left: 0%;
  margin-right: 0%;
  display: block;
}

.out-link {
  color: black;
}

.hidden {
  visibility: hidden;
}

.none {
  display: none;
}

div.img-container {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  text-align: center;
}

@media only screen and (min-width: 500px) {
  .center-vertical {
    margin-top: -100px; /* Half the height */
    margin-left: -250px; /* Half the width */
  }
}

@media only screen and (max-width: 500px) {
  .center-vertical {
    margin-top: -100px; /* Half the height */
    margin-left: -150px; /* Half the width */
  }
}

.center-vertical {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 200px;
  max-width: 80%;
}

.black-arrow {
  color: grey;
  font-size: 50px;
}

.splash {
  height: 1000px;
}

.splash-photo {
  width: 80%;
  display: block;
  margin: auto;
}

footer {
  background-color: black;
  height: 200px;
  color: white;
  position: relative;
}

.footer-copyright {
  position: absolute;
  bottom: 0;
  left: 20px;
}

.width-50px {
  width: 50px;
}

.max-height-300 {
  max-height: 300px;
  max-width: 100%;
}

.store {
  min-height: 700px;
}

.absolute-top-right {
  position: absolute;
  top: 0px;
  right: 0px;

}

.orange-red {
  color: rgb(234,53,56);
  font-size: 20px;
}

.orange-red:hover {
  color: rgb(234,53,56);
  font-size: 20px;
  text-decoration: none;
}

@media only screen and (min-width: 1000px) {
  .d-grid-desktop {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    text-align: center;
  }
}

.min-2000 {
  min-height: 2000px;
}
